import { SET_MODAL_DATA, SET_MODAL_OPEN_STATE, CLEAR_MODAL_DATA, CLOSE_MODAL, OPEN_MODAL } from '../actions.type'

export const state = {
  SelectDeviceModal: {
    isOpened: false,
    data: {},
  },
}

export const mutations = {
  [SET_MODAL_DATA](state, { id, data }) {
    state[id].data = data || {}
  },
  [SET_MODAL_OPEN_STATE](state, { id, isOpened }) {
    state[id].isOpened = isOpened
  },
  [CLEAR_MODAL_DATA](state, id) {
    state[id].data = {}
  },
}

export const actions = {
  [OPEN_MODAL](context, { id, data }) {
    context.commit(SET_MODAL_DATA, { id, data })
    context.commit(SET_MODAL_OPEN_STATE, { id, isOpened: true })
  },
  [CLOSE_MODAL](context, id) {
    context.commit(SET_MODAL_OPEN_STATE, { id, isOpened: false })
    context.commit(CLEAR_MODAL_DATA, id)
  },
}

export const getters = {}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}