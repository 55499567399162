import Vue from 'vue'
import Vuex from 'vuex'

import settings from './modules/settings'
import devices from './modules/devices'
import device from './modules/device'
import topic from './modules/topic'
import search from './modules/search'
import crumbs from './modules/crumbs'
import modals from './modules/modals'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    settings,
    devices,
    device,
    topic,
    search,
    crumbs,
    modals
  },
})
