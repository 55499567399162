<template>
  <div class="device-detection">
    <div id="device-detector___BV_modal_outer_" style="position: absolute; z-index: 1040">
      <div id="device-detector" class="modal fade" :class="isOpened ? 'show' : ''">
        <div class="modal-dialog modal-md modal-dialog-centered">
          <span tabindex="0"></span>
          <div id="device-detector___BV_modal_content_" tabindex="-1" class="modal-content" v-click-outside="hideModal">
            <header id="device-detector___BV_modal_header_" class="modal-header">
              <h5 id="device-detector___BV_modal_title_" class="modal-title"></h5>
            </header>
            <div id="device-detector___BV_modal_body_" class="modal-body">
              <div class="sp-modal-header">
                <button type="button" class="sp-close-modal" v-on:click="hideModal()">×</button>
                <h5 class="modal-title">{{ modalTitle }}</h5>
              </div>
              <div class="detection-devices-wrap" v-if="data.devices && data.devices.length">
                <router-link
                  v-for="(d, i) in data.devices"
                  :key="i"
                  :to="generateDeviceLink(d)"
                  class="btn btn-detection"
                  @click.native="hideModal()"
                >
                  {{ d.name }}
                </router-link>
              </div>
              <div v-else-if="data.brand" class="detection-devices-wrap">
                <router-link
                  :to="{ name: 'device-selector', params: { brand: data.brand.slug } }"
                  class="btn btn-detection"
                  @click.native="hideModal()"
                  >{{ getBrandName(data.brand) }}</router-link
                >
              </div>
              <div class="detection-devices-wrap">
                <h4 class="font-weight-normal">{{ $t('strings.or-select') }}</h4>
                <button class="btn btn-detection btn-another" @click="hideModal">
                  {{ $t('strings.another-device') }}
                </button>
              </div>
            </div>
          </div>
          <span tabindex="0"></span>
        </div>
      </div>
      <div id="device-detector___BV_modal_backdrop_" :class="isOpened ? 'show' : 'fade'" class="modal-backdrop"></div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import { CLOSE_MODAL } from '@/store/actions.type'
const { mapActions } = createNamespacedHelpers('modals')
export default {
  data() {
    return {
      id: 'SelectDeviceModal',
    }
  },
  watch: {
    isOpened(val) {
      if (val) {
        document.body.classList.add('modal-open')
      } else {
        document.body.classList.remove('modal-open')
      }
    },
  },
  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his children
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    },
  },
  computed: {
    isOpened: {
      get() {
        return this.$store.state.modals[this.id].isOpened
      },
      set(value) {
        if (!value) {
          this.hideModal()
        }
      },
    },
    data() {
      return this.$store.state.modals[this.id].data
    },
    modalTitle() {
      return this.$t('strings.get-information-on')
    },
  },
  methods: {
    ...mapActions([CLOSE_MODAL]),
    hideModal() {
      this.closeModal(this.id)
      this.$store.commit('devices/setDetectionState', true)
    },
    getDeviceSlug(d) {
      return (d.operatingSystems && d.operatingSystems[0] && d.operatingSystems[0].slug) || d.slug
    },
    generateDeviceLink(d) {
      return {
        name: 'device',
        params: {
          brand: d.brand.slug,
          model: (d.operatingSystems && d.operatingSystems[0] && d.operatingSystems[0].slug) || d.slug,
        },
      }
    },
    getBrandName(brand) {
      var out = brand.text
      switch (brand.slug) {
        case 'lg':
          out = out.toUpperCase()
          break
      }
      return out
    },
  },
}
</script>

<style lang="scss">
#speedperform {
  .modal {
    .modal-dialog-centered {
      min-height: 400px;
      margin: 40px 15px;
    }

    .modal-content {
      border: none;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
      border-radius: 15px;
    }

    .modal-header {
      display: none;
    }

    .sp-modal-header {
      justify-content: center;
      padding-top: 16px;
      border-bottom: none;
      margin-bottom: 10px;
      margin: 24px;

      h5 {
        font-family: 'Frutiger-Bold';
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-transform: inherit;
        color: #000066;
        text-align: center;
      }
    }

    .modal-body {
      padding-bottom: 36px;

      h4 {
        font-family: 'Frutiger-Medium';
        text-align: center;
        font-size: 18px;
        line-height: 24px;
        color: #000066;
        margin: 16px;
      }
    }

    .detection-devices-wrap {
      margin: 0 auto;
      min-width: 230px;
      padding: 0 30px 0 30px;
    }

    .btn {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: inherit !important;
      margin-bottom: 8px;
    }

    .btn.btn-detection {
      font-family: 'Frutiger-Bold';
      border: 2px solid #0073a8;
      border-radius: 8px;
      font-size: 16px;
      line-height: 40px;
      background: none;
      color: #0073a8;
      min-width: 200px;
      text-decoration: none;
      width: 100%;

      &.btn-another {
        color: #fff;
        background: #0073a8;
      }
    }
  }

  #device-detector {
    .sp-close-modal {
      position: absolute;
      top: 15px;
      right: 15px;
      color: #0073a8;
      font-family: 'Frutiger-Bold';
      font-size: 36px;
      opacity: 1;
      background-color: transparent;
      border: none;
    }
  }
}
</style>
