import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

export const ApiService = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL
    if (process.env.VUE_APP_API_TOKEN) {
      Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${process.env.VUE_APP_API_TOKEN}`
    }
    Vue.axios.defaults.headers.common['ClientId'] = `${process.env.VUE_APP_API_CLIENT_ID}`
    Vue.axios.defaults.headers.common['ChannelId'] = `${process.env.VUE_APP_API_CHANNEL_ID}`
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[SP] ApiService ${error}`)
    })
  },

  get(resource, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[SP] ApiService ${error}`)
    })
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params)
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params)
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[SP] ApiService ${error}`)
    })
  },
}

export const DeviceService = {
  query(type, params) {
    return ApiService.query('devices', params)
  },
  get(brand, slug) {
    return ApiService.get(`/devices/${brand}/${slug}`)
  },
  getTopic(brand, device, topic) {
    return ApiService.get(`devices/${brand}/${device}/topics`, topic)
  },
  getDetectionDevices(params) {
    var instance = axios.create()
    instance.defaults.headers.common = {}
    return instance.get(`https://wm-api.com/detect/me`, params)
  },
}

export const SearchService = {
  post(params) {
    return ApiService.post('search', params)
  },
  getSearchResultsFlat(params) {
    return ApiService.post('search/flat', params)
  },
  getDevicesByGeneric({ genericItemId, genericType, query }) {
    return ApiService.post(`search/${genericType}`, { contextItemId: genericItemId, query })
  },
  getGenericContentItem({ method, deviceId, cmtReferenceId }) {
    return ApiService.query(`${method}/${deviceId}/${cmtReferenceId}`)
  },
}

export const AdminFeedbackService = {
  sendFeedback(isHelpful, url, title, comment, device = '', manualId = '0') {
    let query = `?callback=0&clientId=o2uk&inputType=0&applicationId=0&comment=${comment}&device=${device}&manualId=${manualId}&isSatisfied=${isHelpful}&vote=${
      isHelpful ? 5 : 0
    }&url=${url}&PageTitle=${title}`
    return axios.get(`https://admin.speedperform.com/feedback/savefeedbackfromajax${query}`)
  },
}

export const GenericInstructionService = {
  query() {
    return ApiService.query('generic-instruction/list')
  },
  get(genericInstructionId) {
    return ApiService.get('generic-instruction/id', genericInstructionId)
  },
}

export const ProblemService = {
  get(problemSlug) {
    return ApiService.get(`devices/problem/${problemSlug}`)
  },
}
