import Vue from 'vue'
import VueRouter from 'vue-router'
import { qelpRedirect } from './redirectHelper'
import { qelpArticleRedirect } from './redirectHelper'

Vue.use(VueRouter)

export const allowedBaseUrls = [
  '/web/o2static-staging/liferay-archive/shannon-test',
  '/web/o2static-staging/liferay-archive/shannon-test-edit',
  '/web/o2static-staging/phones-sims-and-devices',
  '/web/o2static-staging/help/phones-sims-and-devices',
  '/web/o2static-staging/help/phones-sims-and-devices-edit',
  '/help/phones-sims-and-devices',
  '/business/support/devices',
  '/web/o2static-staging/devices-edit',
  '/web/o2static-staging/business/support/devices',
]
const base = allowedBaseUrls.find((x) => location.pathname.startsWith(x)) || '/'

let router = new VueRouter({
  mode: 'history',
  base,
  routes: [
    {
      name: 'device-selector',
      path: '/:brand?',
      component: () => import('@/pages/device-selector'),
      meta: {
        breadcrumb: 'Home',
      },
    },
    {
      name: 'device',
      path: '/:brand/:model',
      component: () => import('@/pages/device'),
      children: [
        {
          name: 'device.section',
          path: ':section?',
          component: () => import('@/pages/device-section'),
        },
        {
          name: 'device.topic',
          path: ':section/:topic',
          component: () => import('@/pages/device-topic'),
        },
      ],
    },
    {
      name: 'not-found',
      path: '*',
      component: () => import('@/pages/page-not-found'),
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const articleRedirect = await qelpArticleRedirect(to.path)
  if (articleRedirect) {
    window.location.href = articleRedirect;
  } else {
    const path = await qelpRedirect(to.path)
    if (path) return next(path)
    return next()
  }
})

export default router
