import { SearchService, GenericInstructionService, ProblemService } from '@/common/api.service'
import {
  SET_ERROR,
  POST_SEARCH,
  FLAT_SEARCH,
  SEARCH_START,
  SEARCH_END,
  FETCH_GENERIC_INSTRUCTIONS,
  FETCH_PROBLEMS
} from '../actions.type'

export const SEARCH_RESULT_TYPE = {
  Article: 1,
  Device: 2,
  Instruction: 3,
  Problem: 4,
  1: 'Article',
  2: 'Device',
  3: 'Instruction',
  4: 'Problem',
}

export const state = {
  results: {},
  devices: {},
  isLoading: false
}

export const actions = {
  [POST_SEARCH](context, { query, options }) {
    context.commit(SEARCH_START)
    return SearchService.post({ query, ...options })
      .then(({ data }) => {
        context.commit(SEARCH_END, { results: data })
      })
      .catch((error) => {
        throw new Error(error)
      })
  },
  [FLAT_SEARCH](context, { query, options }) {
    context.commit(SEARCH_START)
    return SearchService.getSearchResultsFlat({ query, ...options })
      .then(({ data }) => {
        context.commit(SEARCH_END, { results: data })
      })
      .catch((error) => {
        throw new Error(error)
      })
  },
  [FETCH_GENERIC_INSTRUCTIONS]() {
    return GenericInstructionService.query()
  },
  [FETCH_PROBLEMS](context, {problemSlug}) {
    return ProblemService.get(problemSlug).then(({ data }) => {
      const brands = [...new Map(data?.map((d) => [d.manufacturer, d.manufacturer.toLowerCase()]) || [])]
      context.commit(FETCH_END, { data, brands })
    })
  }
}

export const mutations = {
  // Global search mutations
  [SEARCH_START](state) {
    state.isLoading = true
  },
  [SEARCH_END](state, data) {
    state.results = data.results
    state.isLoading = false
  },
  [SET_ERROR](state, error) {
    state.errors = error
  },
}

const getters = {
  results(state) {
    return state.results
  },
  isLoading(state) {
    return state.isLoading
  },
  searchResultType() {
    return SEARCH_RESULT_TYPE
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
