import { ApiService } from '@/common/api.service'
import { FETCH_END, FETCH_SETTINGS, FETCH_START, SET_ERROR } from '../actions.type'

export const state = {
  settings: null,
  errors: null,
  isPreview: false,
  content: [
    { name: '' },
  ],
}

export const actions = {
  [FETCH_SETTINGS](context) {
    context.commit(FETCH_START)
    ApiService.query('settings')
      .then(({ data }) => {
        context.commit(FETCH_END, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors)
      })
  },
}

export const mutations = {
  [FETCH_START](state) {
    state.isLoading = true
  },
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [FETCH_END](state, data) {
    state.settings = data
    state.errors = {}
    state.isLoading = false
  },
  setPreview(state, isPreview) {
    state.isPreview = isPreview
  },
}

const getters = {
  isLoading(state) {
    return state.isLoading
  },
  isSetup(state) {
    return !!state.settings
  },
  isPreview(state) {
    return state.isPreview
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
