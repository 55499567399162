import store from '../store'

const DEVICE_RELATIONS = {
  'galaxy-a52s': 'galaxy-a52',
  a54: 'a54-5g',
}

const GUIDE_RELATIONS = {
  'internet-and-data-set-up-manually': 'set-up-your-device-for-internet',
  'reset-device-how-to-perform-soft-reset': 'restart-device',
  'settings-how-to-activate-volte': 'turn-volte-on-or-off',
  'internet-and-data-switch-between-2g': 'select-network-mode',
  'first-setup-how-to-insert-the-sim': 'insert-sim',
  'voicemail-visual-voicemail': 'use-visual-voicemail',
  'settings-how-to-activate-vowifi': 'turn-wifi-calling-on-or-off',
  'settings-how-to-enable-4g-lte': 'select-network-mode',
  'abroad-turn-off-data-roaming': 'turn-data-roaming-on-or-off',
  'texts-and-picture-messages-set-up-mms-manually': 'set-up-your-device-for-picture-messaging',
  'reset-device-reset-to-factory-settings': 'restore-factory-default-settings',
  'internet-and-data-how-to-setup-mobile-hotspot-on-your-device': 'use-device-as-wifi-hotspot',
  'wifi-enable-wifi-and-select-a-network': 'connect-to-a-wifi-network',
  'settings-setup-call-forwarding': 'setup-call-forwarding',
  'internet-and-data-turn-off-mobile-data': 'turn-mobile-data-on-or-off',
  'software-install-software-update-wirelessly': 'update-device-software',
  'software-connect-the-device-to-a-pc': 'transfer-files-between-computer-and-device',
  'bluetooth-turn-on-and-pair-devices': 'pair-a-Bluetooth-device-with-your-device',
  'transfer-contacts-android-to-android': 'transfer-content-from-another-device',
}

const ARTICLES_RELATIONS = {
  '/unlocking-an-o2-mobile-to-use-on-a-different-network': 'https://www.o2.co.uk/help/device-and-sim-support/unlocking-an-o2-mobile-to-use-on-a-different-network',
  '/collection-and-delivery': 'https://www.o2.co.uk/help/device-and-sim-support/collection-and-delivery',
  '/how-to-keep-your-mobile-number': 'https://www.o2.co.uk/help/device-and-sim-support/how-to-keep-your-mobile-number	',
  '/how-to-disconnect-your-non-o2-numbr': 'https://www.o2.co.uk/help/device-and-sim-support/how-to-disconnect-your-non-o2-number',
  '/imei-explained': 'https://www.o2.co.uk/help/device-and-sim-support/imei-explained	',
  '/apple-watch': 'https://www.o2.co.uk/help/device-and-sim-support/apple-watch',
  '/missed-call-and-abandoned-call-alerts': 'https://www.o2.co.uk/help/device-and-sim-support/missed-call-and-abandoned-call-alerts	',
  '/our-change-of-mind-policy': 'https://www.o2.co.uk/help/device-and-sim-support/our-change-of-mind-policy	',
  '/our-faults-and-repairs-policy': 'https://www.o2.co.uk/help/device-and-sim-support/our-faults-and-repairs-policy	',
  '/returning-your-ios7-device': 'https://www.o2.co.uk/help/device-and-sim-support/returning-your-ios7-device	',
  '/phone-health-check': 'https://www.o2.co.uk/help/device-and-sim-support/phone-health-check',
  '/esim': 'https://www.o2.co.uk/help/device-and-sim-support/esim	',
  '/activating-your-sim': 'https://www.o2.co.uk/help/device-and-sim-support/activating-your-sim',
  '/lost-or-stolen-device': 'https://www.o2.co.uk/help/device-and-sim-support/lost-or-stolen-device	',
  '/how-to-keep-your-mobile-number/online-form': 'https://www.o2.co.uk/help/device-and-sim-support/how-to-keep-your-mobile-number/online-form',
  '/our-returns-policy': 'https://www.o2.co.uk/help/device-and-sim-support/returns-and-repairs',
  '/unblock-your-phone': 'https://www.o2.co.uk/help/device-and-sim-support/unblock-your-phone',
  '/how-to-disconnect-your-non-o2-number/online-form/success': 'https://www.o2.co.uk/help/device-and-sim-support/how-to-disconnect-your-non-o2-number/online-form/success',
  '/unlocking-an-o2-mobile-to-use-on-a-different-network/online-form': 'https://www.o2.co.uk/help/device-and-sim-support/unlocking-an-o2-mobile-to-use-on-a-different-network/online-form',
  '/amazon-fire-phone-support': 'https://www.o2.co.uk/help/device-and-sim-support/amazon-fire-phone-support',
  '/like-new': 'https://www.o2.co.uk/help/device-and-sim-support/like-new',
  '/pocket-hotspot': 'https://www.o2.co.uk/help/device-and-sim-support/pocket-hotspot',
  '/help-with-your-sim': 'https://www.o2.co.uk/help/device-and-sim-support',
  '/o2-refresh-trade-in': 'https://www.o2.co.uk/help/device-and-sim-support/o2-refresh-trade-in',
  '/2g-sim-swap': 'https://www.o2.co.uk/help/device-and-sim-support/2g-sim-swap',
  '/help-with-mobile-broadband': 'https://www.o2.co.uk/help/device-and-sim-support/help-with-mobile-broadband',
  '/help-with-mobile-broadband/changing-your-pay-as-you-go-plan-for-tablets-pocket-hotspots-or-dongles': 'https://www.o2.co.uk/help/device-and-sim-support/help-with-mobile-broadband/changing-your-pay-as-you-go-plan-for-tablets-pocket-hotspots-or-dongles',
  '/help-with-mobile-broadband/pay-as-you-go-information-for-android-tablet-and-mobile-broadband-users': 'https://www.o2.co.uk/help/device-and-sim-support/help-with-mobile-broadband/pay-as-you-go-information-for-android-tablet-and-mobile-broadband-users',
  '/help-with-mobile-broadband/managing-your-account': 'https://www.o2.co.uk/help/device-and-sim-support/help-with-mobile-broadband/managing-your-account',
  '/help-with-mobile-broadband/problems-connecting': 'https://www.o2.co.uk/help/device-and-sim-support/help-with-mobile-broadband/problems-connecting',
  '/help-with-mobile-broadband/topping-up-and-checking-your-balance': 'https://www.o2.co.uk/help/device-and-sim-support/help-with-mobile-broadband/topping-up-and-checking-your-balance',
  '/help-with-mobile-broadband/activating-your-pay-as-you-go-data-sim': 'https://www.o2.co.uk/help/device-and-sim-support/help-with-mobile-broadband/activating-your-pay-as-you-go-data-sim',
  '/help-with-mobile-broadband/upgrading-your-device': 'https://www.o2.co.uk/help/device-and-sim-support/help-with-mobile-broadband/upgrading-your-device',
  '/help-with-mobile-broadband/pay-as-you-go-information-for-ipad-users': 'https://www.o2.co.uk/help/device-and-sim-support/help-with-mobile-broadband/pay-as-you-go-information-for-ipad-users',
  '/help-with-mobile-broadband/pay-as-you-go-tablets-and-data': 'https://www.o2.co.uk/help/device-and-sim-support/help-with-mobile-broadband/pay-as-you-go-tablets-and-data',
}

export async function qelpRedirect(path) {
  path = path.replace('/!', '').replace(/^\/|\/$/g, '')
  const parts = path.split('/')
  if (parts[0] === 'devices') {
    if (parts[2]) {
      return `/${parts[2]}?category=${parts[1]}`
    } else if (parts[1]) {
      return `/?category=${parts[1]}`
    } else {
      return '/'
    }
  } else if (parts[0] === 'device') {
    let devices = store.getters['devices/devices'] || []
    if (devices.length === 0) {
      await store.dispatch('devices/fetchDevices')
      devices = store.getters['devices/devices'] || []
    }

    let md
    let slug = DEVICE_RELATIONS[parts[2]]
    if (slug) {
      md = devices.find((d) => d.slug === slug)
    } else {
      md = devices.find((d) => {
        let equal = true
        for (const el of parts[2].split('-')) {
          if (!d.slug.includes(el)) {
            equal = false
            break
          }
        }
        return equal && d.brand.slug === parts[1]
      })
    }

    if (!md) return '/'

    const brand = md.brand.slug
    const model = (md.operatingSystems && md.operatingSystems[0] && md.operatingSystems[0].slug) || md.slug

    let redirectUrl = `/${brand}/${model}`

    if (parts[3] === 'topic' && parts[4] && parts[5]) {
      const guideSlug = GUIDE_RELATIONS[`${parts[4]}-${parts[5]}`]
      if (!guideSlug) return redirectUrl

      const device = store.getters['device/device']
      if (!device || device.slug !== model) {
        await store.dispatch('device/fetchDeviceContent', { slug: model })
      }

      const topics = store.getters['device/content']
      const topic = topics.find(
        (t) => t.chapters && t.chapters.find((c) => c.guides && c.guides.findIndex((guide) => guide.slug === guideSlug))
      )

      return `${redirectUrl}/${topic.slug}/${guideSlug}`
    }

    return redirectUrl
  }

  return null
}

export async function qelpArticleRedirect(path) {
  var articlePath = path.split('?')[0]
  if (ARTICLES_RELATIONS[`${articlePath}`]) {
    var redirectPath = ARTICLES_RELATIONS[`${articlePath}`]
    return redirectPath
  }
}
