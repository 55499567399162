import { DeviceService } from '@/common/api.service'
import router from '@/router'

import { FETCH_DEVICE_CONTENT, FETCH_END, FETCH_START, SET_ERROR } from '../actions.type'

export const state = {
  device: null,
  content: [
    {
      name: '',
    },
  ],
  mostViewedContent: {},
  isLoading: true,
  specs: [
    {
      specificationGroups: [
        {
          specifications: [],
        },
      ],
    },
  ],
}

export const actions = {
  [FETCH_DEVICE_CONTENT](context, { brand, slug }) {
    context.commit(FETCH_START)
    return DeviceService.get(brand, slug)
      .then(({ data }) => {
        if (!data) {
          throw new Error('404')
        }
        if (data.redirectTo) {
          router.replace(data.redirectTo)
        }
        context.commit(FETCH_END, data)
      })
      .catch((error) => {
        throw new Error(error)
      })
  },
}

export const mutations = {
  [FETCH_START](state) {
    state.isLoading = true
  },
  [FETCH_END](state, { device, content, mostViewedContent, specs }) {
    state.device = device
    state.content = content
    state.mostViewedContent = mostViewedContent
    state.specs = specs
    state.isLoading = false
  },
  [SET_ERROR](state, error) {
    state.errors = error
  },
}

const getters = {
  device(state) {
    return state.device
  },
  content(state) {
    return state.content
  },
  mostViewedContent(state) {
    return state.mostViewedContent
  },
  specs(state) {
    return state.specs
  },
  isLoading(state) {
    return state.isLoading
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
