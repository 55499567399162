export default function(Vue, { debug, enabled }) {
    Vue.prototype.$tealiumTracking = {
      trackPage,
      trackEvent,
    }

    function trackPage(path, section1 = '', section2 = '', section3 = '', section4 = '') {
      const sections = [section1, section2, section3, section4]
      const sectionsString = sections.filter(s => s !== null && s.length > 1).join('|')
      
        window.utag_data = {
            "page_page_name": "portal:con|help|devicehelp|" + sectionsString,
            "page_sitesection1": section1,
            "page_sitesection2": section2,
            "page_sitesection3": section3,
            "page_sitesection4": section4,
            "page_channel": "portal:con",
            "page_cms": "speedperform"
        }
        if (enabled && window.utag) {
            window.utag.view(utag_data);
            debug && console.log('Tealium tracking:', path)
        }
    }
  
    function trackEvent(event, page = '') {
        utag.link({
            "tealium_event": event,
            "page_name": page
        })
    }
  }
