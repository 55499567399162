import { DeviceService } from '@/common/api.service'
import router from '@/router'
import { FETCH_DEVICE_TOPIC, FETCH_END, FETCH_START, SET_ERROR } from '../actions.type'

export const state = {
  topic: {},
  isLoading: true,
}

export const actions = {
  [FETCH_DEVICE_TOPIC](context, { brand, device, topic }) {
    context.commit(FETCH_START)
    return DeviceService.getTopic(brand, device, topic)
      .then(({ data }) => {
        if (data.redirectTo) {
          router.replace(data.redirectTo)
        }
        context.commit(FETCH_END, data)
      })
      .catch((error) => {
        throw new Error(error)
      })
  },
}

export const mutations = {
  [FETCH_START](state) {
    state.isLoading = true
  },
  [FETCH_END](state, data) {
    state.topic = data
    state.isLoading = false
  },
  [SET_ERROR](state, error) {
    state.errors = error
  }
}

const getters = {
  topic(state) {
    return state.topic
  },
  isLoading(state) {
    return state.isLoading
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}