<template>
  <div id='speedperform'>
    <div class='container-fluid p-0'>
      <!-- <breadcrumb /> -->
      <!--      <preview-mode-banner />-->
      <router-view />
      <select-device-modal />
    </div>
  </div>
</template>

<script>
// import Breadcrumb from '@/components/breadcrumb'
import SelectDeviceModal from '@/components/modals/select-device-modal'
import { FETCH_SETTINGS } from '@/store/actions.type'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('settings')

export default {
  name: 'App',
  metaInfo: {
    titleTemplate: 'O2 | %s',
  },
  data() {
    return {
      isLoading: true,
    }
  },
  created() {
    this.fetchSettings()
  },
  methods: {
    ...mapActions([FETCH_SETTINGS]),
  },
  components: {
    // Breadcrumb,
    SelectDeviceModal
  },
}
</script>
