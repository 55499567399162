import { DeviceService, GenericInstructionService, ProblemService } from '@/common/api.service'
import {
  FETCH_DEVICES,
  FETCH_DETECTION_DEVICES,
  FETCH_END,
  FETCH_START,
  SET_ERROR,
  SET_DETECTION_DEVICES,
  SET_DETECTION_BRAND,
  SET_DETECTION_STATE,
} from '../actions.type'
import utils from '@/utils'

export const state = {
  brands: [],
  devices: [],
  detectedOptions: {
    showed: false,
    devices: [],
    brand: null,
  },
  problemName: null,
  isLoading: true,
}

export const actions = {
  [FETCH_DEVICES](context, {genericInstructionId = null, problemSlug = null}) {
    context.commit(FETCH_START)
    if (!genericInstructionId && !problemSlug)
      return DeviceService.query()
        .then(({ data }) => {
          context.commit(FETCH_END, data)
        })
        .catch((error) => {
          throw new Error(error)
        })
    else if (genericInstructionId) {
      return GenericInstructionService.get(genericInstructionId)
        .then(({ data }) => {
          const devices = (data.devices || []).filter(
            (d) => d.online && (d.hasGenericInstruction || d.operatingSystems[0]?.hasGenericInstruction)
          )
          let brands = [...new Map(devices?.map((d) => [d.brand.slug, d.brand]) || []).values()]
          brands = brands.map(b => ({ ...b, online: true }))
          context.commit(FETCH_END, { devices, brands })
        })
        .catch((error) => {
          throw new Error(error)
        })
      } else if (problemSlug) {
        return ProblemService.get(problemSlug).then(({ data }) => {

          // device in data.devices from API does not include device ID, so not able to match to online state from Admintool
          // let admintoolOnlineDevices = context.rootState.settings.settings?.adminData?.onlineDevices
          // if (admintoolOnlineDevices) admintoolOnlineDevices = admintoolOnlineDevices.map(d => "F-" + d.manualId)
          // let devices = data.devices.filter((d) => admintoolOnlineDevices.includes(d.Id))
          let devices = data.devices.map(d => ({ ...d, online: true })) //set all devices online instead

          const manufacturers = [...new Map(devices?.map((d) => [d.manufacturer, d.manufacturer.toLowerCase()]) || [])]
          var brands = [];
          for(var i = 0; i < manufacturers.length; i++) {
            brands.push({name:manufacturers[i][0], slug:manufacturers[i][1], online: true});
          }
          state.problemName = data?.title
          context.commit(FETCH_END, { devices, brands })
        })
      }
  },
  [FETCH_DETECTION_DEVICES](context, genericInstructionId) {
    var hardwareInfoObj = {
      w: window.screen.width || 0,
      h: window.screen.height || 0,
      r: ~~((window.devicePixelRatio || 1) * 100),
    }
    var browserResolve = (navigator && navigator.userAgentData && navigator.userAgentData && navigator.userAgentData.getHighEntropyValues) ? navigator.userAgentData.getHighEntropyValues([
      "model",
      "platform",
      "platformVersion",
      "fullVersionList",
    ]) : new Promise(resolve => {
      resolve();
    });
    var hardwareInfoStr = Object.keys(hardwareInfoObj)
      .reduce(function (a, k) {
        a.push(encodeURIComponent(hardwareInfoObj[k]))
        return a
      }, [])
      .join(':')

    browserResolve.then((ua) => {
      var model = (ua) ? ua.model : null;
      return DeviceService.getDetectionDevices({
        params: {
          _: new Date().getTime(),
          'api-key': '287f43e0-3f4b-4988-b259-066bb706afcb',
          'modelIdentifier': model,
          hardwareInfo: hardwareInfoStr,
          version: 2,
        },
      }).then(({ data }) => {
        let devices = []
        if (data.Device != null) {
          devices.push(data.Device)
        } else if (data.AlternativeDevices != null) {
          devices = data.AlternativeDevices
        } else if (data.SuggestedManufacturer) {
          context.commit(SET_DETECTION_BRAND, data.SuggestedManufacturer)
        }
        if (devices.length) {
          devices.forEach((d) => {
            d.Id = utils.fixWmAdminDeviceId(d.Id)
          })
          context.commit(SET_DETECTION_DEVICES, devices)
        }
      })
    })
  },
}

export const mutations = {
  [FETCH_START](state) {
    state.isLoading = true
  },
  [FETCH_END](state, { brands, devices }) {
    state.brands = brands
    state.devices = devices
    state.isLoading = false
  },
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_DETECTION_DEVICES](state, devices) {
    state.detectedOptions.devices = devices
  },
  [SET_DETECTION_BRAND](state, brand) {
    state.detectedOptions.brand = brand
  },
  [SET_DETECTION_STATE](state, value) {
    state.detectedOptions.showed = value
  },
}

const getters = {
  brands(state, getters, rootState, rootGetters) {
    if (process.env.VUE_APP_DISABLE_CONTENT_ADMIN) {
      return state.brands
    }
    const isPreview = rootGetters['settings/isPreview']
    if (isPreview) {
      return state.brands
    }

    return state.brands.filter((x) => x.online)
  },
  devices(state, getters, rootState, rootGetters) {
    if (process.env.VUE_APP_DISABLE_CONTENT_ADMIN) {
      return state.devices
    }
    const isPreview = rootGetters['settings/isPreview']
    if (isPreview) {
      return state.devices
    }
    return state.devices.filter((x) => x.online)
  },
  isLoading(state) {
    return state.isLoading
  },
  detectedOptions(state) {
    return state.detectedOptions
  },
  problemName(state) {
    return state.problemName
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
