import App from '@/App.vue'
import '@/assets/scss/app.scss'
import { ApiService } from '@/common/api.service'
import Sampling from '@/plugins/sampling'
import tealium from '@/plugins/tealium'
import router from '@/router'
import store from '@/store'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import VueFuse from 'vue-fuse'
import VueI18n from 'vue-i18n'
import VueMeta from 'vue-meta'
import VueScrollTo from 'vue-scrollto'
import utils from '@/utils'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import filters from '@/filters'

const sentry_dsn = process.env.VUE_APP_SENTRY_DSN || ''
if (sentry_dsn.length > 0) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    logErrors: true, //process.env.NODE_ENV !== 'production',
    environment: process.env.NODE_ENV,
    allowUrls: [/https?:\/\/((cdn|api)\.)?sp-selfservice\.com/],
    autoSessionTracking: false,
  })
}

// Preview mode
const preview = window.location.search.indexOf('preview') != -1
store.commit('settings/setPreview', preview)

Vue.config.productionTip = false
Vue.use(VueFuse)
Vue.use(VueMeta)
Vue.use(VueAwesomeSwiper)
utils.cleanUpHeadTag()
Vue.use(VueI18n)
Vue.use(Sampling, { enabled: !preview })
Vue.use(tealium, { debug: false, enabled: !preview })
Vue.use(VueScrollTo)
Vue.use(filters)

ApiService.init()

new Vue({
  i18n: new VueI18n({
    locale: 'en-UK',
    fallbackLocale: 'en-UK',
    messages: {
      'en-UK': require('./locales/en-UK.json'),
    },
  }),
  router,
  store,
  render: (h) => h(App),
}).$mount('#speedperform')
