export function replaceQuery(url, params) {
  const match = ('' + (url || '')).match(/(.*?)\?/)
  const cleanUrl = match && match[ 1 ] || url
  const enc = encodeURIComponent
  const newQuery = Object.keys(params || {})
    .map(param => typeof params[ param ] !== 'undefined' && params[ param ] !== '' && params[ param ] !== null
      ? `${enc(param)}=${enc(params[ param ])}`
      : enc(param))
    .join('&')
  return cleanUrl + (newQuery && '?' + newQuery || '')
}


export default {
  install(Vue) {
      Vue.filter('replaceQuery', replaceQuery);
  }
}