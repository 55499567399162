import { createPlugin } from '@worldmanuals/sp-sampling'

export default {
  install(Vue) {
    const enabled = process.env.NODE_ENV === 'production'
    const options = {
      enabled,
      apiUrl: process.env.VUE_APP_API_URL.replace(/\/?$/, '/'),
      client: { id: process.env.VUE_APP_API_CLIENT_ID, cmtReferenceId: process.env.VUE_APP_SAMPLING_CLIENT_ID },
      applicationId: 2,
      channelId: process.env.VUE_APP_API_CHANNEL_ID,
      languageId: process.env.VUE_APP_CONTENT_LANGUAGE,
    }

    const Sampling = createPlugin(options)
    Vue.use(Sampling)
  },
}
