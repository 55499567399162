import { SET_CRUMBS } from '../actions.type'

export const state = {
  defaultCrumbs: [
    {
      label: 'Help',
      href: 'https://www.o2.co.uk/help',
    },
    {
      label: 'Phones sims and devices',
      name: 'device-selector',
    },
  ],
  crumbs: [],
}

export const actions = {
  [SET_CRUMBS](context, crumbs) {
    context.commit(SET_CRUMBS, crumbs)
  },
}

export const mutations = {
  [SET_CRUMBS](state, crumbs) {
    state.crumbs = crumbs
  },
}

const getters = {
  crumbs(state) {
    return [...state.defaultCrumbs, ...state.crumbs]
  },
  isLoading(state) {
    return state.isLoading
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
