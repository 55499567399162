export const FETCH_SETTINGS = 'fetchSettings'
export const SET_SETTINGS = 'setSettings'

export const SET_ERROR = 'setError'

export const FETCH_DEVICES = 'fetchDevices'
export const FETCH_DEVICE_CONTENT = 'fetchDeviceContent'
export const FETCH_DEVICE_TOPIC = 'fetchDeviceTopic'
export const FETCH_DETECTION_DEVICES = 'fetchDetectionDevices'
export const SET_DETECTION_DEVICES = 'setDetectionDevices'
export const SET_DETECTION_BRAND = 'setDetectionBrand'
export const SET_DETECTION_STATE = 'setDetectionState'

export const FETCH_END = 'fetchEnd'
export const FETCH_START = 'fetchStart'

export const PAGE_NOT_FOUND = 'pageNotFound'

export const POST_SEARCH = 'postSearch'
export const FLAT_SEARCH = 'flatSearch'
export const SEARCH_START = 'searchStart'
export const SEARCH_END = 'searchEnd'

export const SET_MODAL_DATA = 'setModalData'
export const SET_MODAL_OPEN_STATE = 'setModalOpenState'
export const CLEAR_MODAL_DATA = 'clearModalData'
export const OPEN_MODAL = 'openModal'
export const CLOSE_MODAL = 'closeModal'

export const SET_CRUMBS = 'setCrumbs'

export const FETCH_GENERIC_INSTRUCTIONS = 'fetchGenericInstructions'
export const FETCH_PROBLEMS = 'fetchProblems'
